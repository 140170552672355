@import "colors";

.termscontainer{
    display: flex;
    // width: 100vw;
    padding: 3rem;
    flex-direction: column;
    >h1 {
        text-align: center;
        font-weight: 400;
        // font-size:50px ;
        font-family: "Montserrat";
        color: $dark_blue;
        margin-top: 3rem;
    }
}


    .terms-section {
        margin: 0;
        padding: 80px 0;
      }
      .terms-text {
        margin-bottom: 20px;   
        span {
            margin-left: 20px;
        }
      }
      .terms-list {
        p {
            margin-bottom: 10px;
            i {
                position: relative;
                top: 4px;
                margin-right: 8px;
                color: $dark_blue;
            }
        }
        span {
            margin-left: 20px;
        }

    }
