.home-container {
    display: flex;
    max-width:100vw;
    min-height: 90vh;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background-color: azure;

  }
  
  .left-side {
    width:70vw;
    flex: 1;
    margin-right: 50px;
    >img {
        width: 200px; 
            height: auto
    }


  }
  
  .title {
    font-size: 40px;
    font-weight: bold;
    color: #0E82FD;
  }
  
  .subtitle {
    font-size: 24px;
    font-weight: bold;
  }
  
  .description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .demo-button {
    background-color: #0E82FD;
    color: white;
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .input-group {
    display: flex;
    margin-top: 20px;
  
  }
  
  .input {
    width: 200px;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .call-button {
    background-color: #0E82FD;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .right-side {
    width:30vw;
    flex: 1;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  .Demo{
    margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;

  .input {
    width: 100%;
   padding: 10px;
   margin-top: 10px;
   border: 1px solid #ccc;
   border-radius: 5px;
 }
  }

  @media screen and (max-width: 700px) {

    .home-container {
        display: flex;
        width: 100vw;
      
        padding: 0px 0px;
        align-items: center;
        justify-content: center; /* Center the content horizontally */
      
      }
      
      .left-side {
        display: flex;
        width: 100vw; /* Adjust width to make it smaller */
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px; /* Add some padding */
        margin-right: 0px;

        >img {
            width: 100px; /* Adjust width according to your logo size */
            height: auto
        }
      }
      
      .title {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
       
      }
      
      .subtitle {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
      }
      
      .description {
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 10px;
      }
      
      .demo-button {
        background-color: #0E82FD;
        color: white;
        font-size: 20px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
      
      .input-group {
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: bisque;
      }
      
      .input {
         width: 100%;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
      
      .call-button {
        background-color: #0E82FD;
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 2rem;
      }
      
      .right-side {
        width:0vw;
        display: none;
      }
      
      .image {
        display: none;
    }
    
  }

  footer {
    background-color:#1B3766;
    height: 40px;
    width: 100%;
   padding: 3rem;
    color: black;
    display: flex;
    justify-content: space-between;
  //   grid-template-columns: 4fr 1fr;
  
    > div {
      display: flex;
     // background-color:blueviolet ;
      flex-direction: column;
      justify-content: center;
      >a {
        color : white
      }
      > h1 {
        font: 300 2rem "Montserrat";
        text-transform: uppercase;
        color: #1B3766;
      }
      > p {
        font: 500 1.2rem "Montserrat";
      }
      > h5 {
        font: 300 1.7rem "Montserrat";
        margin: 0.3rem 0;
        text-align: center;
      }
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        > div {
          display: flex;
          // background-color: aqua;
  
          align-items: center;
          justify-content: space-around;
          > a {
            color: #1B3766;
            margin: 1rem;
            font: 900 1rem "Montserrat";
            &:hover {
              color: #1B3766;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 700px) {
  footer{
  >div{
    >h1{
      font-size: x-large;
    }
  }
  }
  }
  